import {CHANGE_EVENT_TAB_DATA, FETCH_EVENT_DATA, TOGGLE_EVENT_LOADING} from "../actions/types";

const initialState = {
    tab: "STREAMS",
    event:{

    },
    showStreams:false,
    loading:true,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_EVENT_TAB_DATA:
            return {
                ...state,
                tab:action.payload
            };
        case FETCH_EVENT_DATA:
            return {
                ...state,
                event:action.payload.event,
                showStreams:action.payload.showStreams,
            };

        case TOGGLE_EVENT_LOADING:
            return {
                ...state,
                loading:action.payload
            };
        default:
            return state;
    }
}