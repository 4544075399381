import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Info extends Component {
    render() {
        return (
            <div className=" d-flex">
                   <div style={{textAlign:'center',padding:'5px'}}>
                       <img style={{width: "85px"}} src={this.props.tournament.logo} alt=""/>
                   </div>
                    <div id="tournament-info">
                        <h4 className="name">{this.props.tournament.uniqueName}</h4>
                        <h6 className="description">Watch every single {this.props.tournament.uniqueName} matches live streaming online for free</h6>

                    </div>

            </div>
        );
    }
}

Info.propTypes = {
    tournament: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired
};
export default Info;
