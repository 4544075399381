import React from 'react'
import classnames from "classnames";
import Streams from "./Streams/Streams";
import {connect} from "react-redux";
import LatestMatches from "./LatestMatches";
import StandingTable from "../Standings/StandingTable";
class Content extends React.Component{

    render() {
        return (
            <div>
                {/*<div className={classnames({*/}
                {/*    'd-none': this.props.tab === 'STREAMS'*/}
                {/*})}>*/}
                {/*    <Channels />*/}
                {/*</div>*/}
                <LatestMatches
                    event={this.props.event}
                />
                <div className={classnames({
                    'd-none': this.props.tab === 'TV'
                })}>
                    <Streams sport={this.props.sport} />
                </div>
                <StandingTable
                    homeTeamId={this.props.event.homeTeam.id}
                    awayTeamId={this.props.event.awayTeam.id}
                    tournamentId={this.props.event.tournament.id}
                    seasonId={this.props.event.seasonId}
                />

            </div>
        );
    }

}
const mapStateToProps = state => ({
    tab: state.event.tab,
    sport: state.sport.slug,
    event: state.event.event,

});
export default connect(mapStateToProps, {})(Content);
