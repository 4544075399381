import React, {Component} from 'react';
import SvBox from "../Common/SvBox";
import ReCAPTCHA from "react-google-recaptcha";
import axios from '../../modules/axios'
import Layout from "../Layout";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            captcha: '',
            showMessage: false

        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();
        const {captcha, name, email, message} = this.state;
        if (captcha === '') {
            return;
        }
        var bodyFormData = new FormData();
        bodyFormData.set('captcha', captcha);
        bodyFormData.set('name', name);
        bodyFormData.set('email', email);
        bodyFormData.set('message', message);

        axios()
            .post('/feedback-soccerstreams', bodyFormData)
            .then(res => {
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    captcha: '',
                    showMessage: true
                });
                document.getElementById('message').value = '';
                this.refs.recaptcha.reset();
            }).catch(res => {
            console.log(res)
        })


    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeRecaptcha(value) {
        this.setState({
            captcha: value
        });
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    componentDidMount() {
        this.scrollToTop();
    }

    componentWillReceiveProps() {
        this.scrollToTop();

    }

    render() {
        return (
            <Layout>
                <SvBox>
                    <h2 className="text-center">Your feedback is important</h2>
                    <p style={{fontSize: "15px"}}>
                        Hi there,<br/>
                        If there something to say to SoccerStreams feel free to do it here. It doesn't matter if its a
                        feature
                        request or a bug report or whatever, We'll be happy to hear it all!
                    </p>
                    <br/>
                    <div className="row justify-content-center">

                        <div className="col-md-6">
                            <div style={{display: this.state.showMessage ? 'block' : 'none'}}
                                 className="alert alert-success">
                                <p>Your feedback has been saved successfully.Thank you</p>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input onChange={this.onChange} value={this.state.name} required type="text"
                                           name="name"
                                           className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input onChange={this.onChange} value={this.state.email} required type="email"
                                           name="email" className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea onChange={this.onChange} name="message" id="message" cols="30" rows="10"
                                              className="form-control">{this.state.message}</textarea>
                                </div>
                                <div className="text-center">

                                    <ReCAPTCHA ref="recaptcha" onExpired={() => this.setState({
                                        captcha: ''
                                    })}
                                               sitekey="6Lf2OIIUAAAAAGQRVR-fwPmchGg120QA2b1-Nwtb"
                                               onChange={this.onChangeRecaptcha}
                                    />
                                    <br/>
                                    <button className="btn btn-success">Submit</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </SvBox>
            </Layout>
        );
    }

}

export default ContactUs;