import React,{Component} from 'react';
import PropTypes from 'prop-types';
import Competition from './Competition';
class Competitions extends Component{
    render() {
        return (
            <ul className="competitions">
                {this.props.competitions.map( (item, i) =>{
                    return (
                        <Competition
                            key={i}
                            {...item}
                                     type={item.status.type}
                                     descrption={item.status.description}
                        />);
                })}

            </ul>
        );
    }
}
Competitions.propTypes = {
    competitions:PropTypes.array.isRequired
};
export default Competitions;