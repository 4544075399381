import React, {Component} from 'react';
import './../icons.css'
import {connect} from "react-redux";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Teams from "./Home/Teams";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: ""
        };
    }


    render() {
        const styles = this.props.styles
        return (
            <div>

                <div id={"search-overlay"}/>
                {!this.props.embed ? (
                    <Header/>
                ) : ''}
                {this.props.loadTeams ? (
                    <>
                        <br/>
                        <Teams/>
                    </>
                ) : ''}
                <div className="main">
                    <div className={this.props.embed ? 'container-fluid' : 'container'}>
                        <div id="layout" className="row">

                            <div id="content" className="col-md-12 pt-1">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                {!this.props.embed ? (
                    <Footer/>
                ) : ''}
                <style type="text/css">{`
                
                :root {
                        --main-color: ${process.env.REACT_APP_COLOR ? process.env.REACT_APP_COLOR : styles.color};
                        --box-bg-color: ${styles.backgroundCSS}
                    }
    `}</style>
            </div>
        )


    }
}


const mapStateToProps = state => ({
    color: state.sport.color,
    styles: state.base.styles,
    embed: state.base.embed,
});
export default connect(mapStateToProps, {})(Layout);
