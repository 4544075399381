import {CHANGE_EVENT_TAB_DATA, FETCH_EVENT_DATA, SET_LEAGUES, TOGGLE_EVENT_LOADING} from "./types";
import axios from "../modules/axios";
import store from "../store";
import moment from "moment-timezone";

Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
};
export const changeEventTabData = (data) => dispatch => {
    dispatch({
        type: CHANGE_EVENT_TAB_DATA,
        payload: data
    })
};
export const fetchEventData = (slug, id) => dispatch => {

    axios().get(`/event/general/${slug}/${id}?type=${store.getState().sport.slug}`)
        .then(res => {
            let showStreams = false;
            if (res.data.event.status.type !== "finished" && moment().subtract(30, "minutes").unix() > (res.data.event.startTimestamp * 1000))
                showStreams = true;
            dispatch({
                type: FETCH_EVENT_DATA,
                payload: {
                    showStreams: showStreams,
                    event: res.data.event
                }
            });
            dispatch({
                type: TOGGLE_EVENT_LOADING,
                payload: false
            });

        })
        .catch(() => {

        });

};
export const fetchTitleEventData = (slug, id) => dispatch => {

    axios().get(`/title-event/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_EVENT_DATA,
                payload: {
                    showStreams: false,
                    event: res.data
                }
            });
            dispatch({
                type: TOGGLE_EVENT_LOADING,
                payload: false
            });

        })
        .catch(() => {

        });

};


export const toggleLoading = (isShow) => dispatch => {
    dispatch({
        type: TOGGLE_EVENT_LOADING,
        payload: isShow
    })
};

function countStreams(events) {
    return events.filter(function (event) {
        return event.redditEventLink !== null && event.status.type !== "finished" && event.status.type !== "canceled"
    }).length
}

function countHighlights(events) {
    return events.filter(function (event) {
        return event.hasHighlights
    }).length
}

export const checkLeagues = () => dispatch => {
    let leagues = store.getState().sport.leagues;
    // leagues = leagues.sort(function (a,b) {
    //     const countStreamsB = countStreams(b.events);
    //     const countStreamsA = countStreams(a.events);
    //     if (countStreamsB )
    //         return 1;
    //
    //     if (countStreamsA ) {
    //         return -1;
    //     }
    //     return 0;
    // });
    //
    // leagues = leagues.filter(function (b) {
    //     if (b.popular  ) {
    //         return true;
    //     }
    //     return false;
    // });
    return leagues;
    leagues = leagues.sort(function (a, b) {
        const countStreamsA = countStreams(a.events);
        const countHighlightsA = countHighlights(a.events);
        const countStreamsB = countStreams(b.events);
        const countHighlightsB = countHighlights(b.events);
        if (b.popular && (countStreamsB)) {
            return 0;
        }
        if ((countStreamsB < countStreamsA)) {
            return -2;
        }
        if ((countStreamsA < countStreamsB)) {
            return 2;
        }
        if (countStreamsA)
            return 0;

        if (b.popular && (countHighlightsB)) {
            return 0;
        }
        if ((countHighlightsB < countHighlightsA)) {
            return -2;
        }
        if ((countHighlightsA < countHighlightsB)) {
            return 2;
        }
        if (countHighlightsA)
            return 0;
        return 1;
    });

    leagues = leagues.map(function (league) {
        const countStreamsA = countStreams(league.events);
        const countHighlightsA = countHighlights(league.events);
        if (countStreamsA || countHighlightsA || league.popular)
            league.open = true;
        else
            league.open = false;
        league.events = league.events.sort(function (a, b) {

            if (a.status.type === "inprogress" && b.status.type !== "inprogress")
                return -1;
            if (a.status.type !== "notstarted" && b.status.type === "notstarted")
                return -2;
            if (b.status.type === "inprogress" && a.status.type !== "inprogress")
                return 1;
            if (b.status.type !== "notstarted" && a.status.type === "notstarted")
                return 2;
            return 0;
        });
        return league;
    });
    leagues = leagues.map(function (league) {
        league.events = league.events.sort(function (a, b) {
            if ((a.hasHighlights && !b.hasHighlights) || (a.hasStreams && !b.hasStreams))
                return -2;
            else
                return 2;
            if ((!a.hasHighlights && b.hasHighlights) || (!a.hasStreams && b.hasStreams))
                return -1;
            else
                return 1;
            return 0;
        });
        return league;
    });

    const selectedTeams = store.getState().sport.selectedTeams.map(function (item) {
        return item.value;
    });
    if (selectedTeams.length) {
        leagues = leagues.map(function (league) {
            const events = league.events.filter(function (item) {
                return  (selectedTeams.indexOf(item.homeTeam.id) !== -1 || selectedTeams.indexOf(item.awayTeam.id) !== -1);
            });
            league.events = events;
            return  league;
        })
        leagues  = leagues.filter(function (league) {
            return league.events.length>0;
        })
    }

    return leagues;

};


export const setLeagues = (leagues) => dispatch => {
    dispatch({
        type: SET_LEAGUES,
        payload: leagues
    })
};
