import React from "react";
import Event from "./Event";
import {connect} from "react-redux";
class Events extends React.Component{

    render() {
        const sport = this.props.sport;
        if (!this.props.events.length)
            return  (
                <React.Fragment/>
            )
        return (
            <ul style={{listStyle:'none',paddingLeft:'0px'}}>
                {this.props.events.map((event,key)=>{
                    return (
                        <Event sport={sport}  {...event} start_time={event.startTimestamp} key={key}/>
                    );
                })}
            </ul>
        );
    }

}
const mapStateToProps = state =>({
    sport:state.sport.slug
});
export default connect(mapStateToProps,{})(Events);
