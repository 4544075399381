import React, {Component} from 'react'
import Loading from "../Common/Loading";
import Info from "./Info";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Layout from "../Layout";
import {Helmet} from "react-helmet";
import {fetchEventData, toggleLoading} from "../../actions/eventActions";
import Content from "./Content";
import {withRouter} from "react-router-dom";

class Event extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            incidents: [],
        };
    }

    fetchData(props) {
        this.props.toggleLoading(true);
        let {id, slug} = props.match.params;
        slug = slug.replace('-live-stream', '');
        this.props.fetchEventData(slug, id);
        window.scrollTo(0, 0);


    }

    componentDidMount() {
        this.fetchData(this.props);


    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.fetchData(nextProps);
        }
    }
    componentWillUnmount() {
        this.props.toggleLoading(true);
    }

    handleClick=()=> {
        this.props.history.goBack()
    };

    render() {
        const sportName = this.props.sportData.name;
        return (
            <Layout>


                <div id="dialog"/>
                {
                    this.props.loading ? (
                        <div id="loading">
                            <Loading loading={this.state.loading}/>
                        </div>
                    ) : (
                        <div>

                            <Helmet>
                                <title>{this.props.event.homeTeam.name + " vs " + this.props.event.awayTeam.name + " live stream - "+sportName+" streams"}</title>
                                <meta property="og:title"
                                      content={this.props.event.homeTeam.name + " vs " + this.props.event.awayTeam.name + " live stream - "+sportName+" streams"}/>

                                <meta property="description"
                                      content={"Watch " + this.props.event.homeTeam.name + " VS " + this.props.event.awayTeam.name + " live streaming online for free.\n You can enjoy " + this.props.event.homeTeam.name + " vs " + this.props.event.awayTeam.name + " live stream here at "+sportName+" streams."}/>
                                <meta property="og:description"
                                      content={"Watch " + this.props.event.homeTeam.name + " VS " + this.props.event.awayTeam.name + " live streaming online for free.\n You can enjoy " + this.props.event.homeTeam.name + " vs " + this.props.event.awayTeam.name + " live stream here at "+sportName+" streams."}/>

                            </Helmet>

                                <div onClick={this.handleClick} style={{color:'#fff !important'}} className="btn btn-ss mb-1 mt-1"><i className="fa fa-arrow-left"/> Back</div>
                            <div className="end">
                                <Info incidents={this.state.incidents}
                                      event={this.props.event}/>
                                <Content/>
                            </div>
                        </div>
                    )
                }
            </Layout>
        );
    }
}

Event.propTypes = {
    fetchEventData: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    event: state.event.event,
    loading: state.event.loading,
    embed: state.base.embed,
    showStreams: state.event.showStreams,
    sportData: state.sport.sportData,
});
export default connect(mapStateToProps, {fetchEventData, toggleLoading})(withRouter(Event));
