import React, {Component} from 'react';
import statusDescription from '../../utilites/statusDescription';
import Score from "./Score";
import StartTime from "./StartTime";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {changeScoreView} from "../../actions/scoreActions";

class Result extends Component {
    score() {
        if (!this.props.showScores)
            return (<span onClick={() => this.props.changeScoreView(!this.props.showScores)}
                          style={{fontWeight: "bold", fontSize: "19px", cursor: "pointer"}}> Show Scores</span>);
        if (this.props.type !== 'notstarted')
            return (<div>
                <Score score={this.props.homeScore.current}/>
                <Score score={this.props.awayScore.current}/>
            </div>);
    }

    render() {
        const statusDescriptionValue = statusDescription(this.props.statusDescription);
        return (
            <div>
                <div className="event-status">{this.props.type !== 'notstarted' ? statusDescriptionValue : (
                    <StartTime timestamp={this.props.startTimestamp}/>)}</div>
                {this.score()}

            </div>
        );
    }

}

Result.propTypes = {
    showScores: PropTypes.bool.isRequired,
    changeScoreView: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
    showScores: state.score.showScores,
});
export default connect(mapStateToProps, {changeScoreView})(Result);
