import React, {Component} from 'react';
import PropTypes from 'prop-types'

import {connect} from "react-redux";
import StatusDescription from "../Index/Matches/StatusDescription";

class Info extends Component {
    score() {
        if (this.props.type === 'notstarted' || !this.props.showScores)
            return '';
        return this.props.homeScore.current + ' - ' + this.props.awayScore.current;
    }

    getPreviewBtn() {
        if (process.env.REACT_APP_HIDE_LIVE_BTN==="true")
            return  ""
        if (this.props.link && this.props.type !== 'finished')
            return (<a target="_blank" href={this.props.link} className="preview-btn btn btn-sm btn-dark">Live</a>);
        if (this.props.type === 'finished')
            if (this.props.hasHighlights)
                return (
                    <a target="_blank" href={this.props.eventLink}
                       className="preview-btn btn btn-sm btn-dark">Highlights</a>);
            else
                return "";
        return "";
    }


    render() {
        return (

            <span onClick={this.props.onClick} style={{minHeight: "35px"}} className="competition-cell-table">
                           <span className="competition-cell-table-cell competition-cell-score  title-event">

                    {this.props.type !== 'notstarted' ? (
                        <div className="preview-btn-center-desktop">{this.score()}</div>
                    ) : ''}
                               <StatusDescription embed={this.props.embed} statusDescription={this.props.statusDescription}
                                                  type={this.props.type}
                                                  startTime={this.props.startTime}/>
                               {/*<div className="preview-btn-center">{this.getPreviewBtn()}</div>*/}
                </span>
                <span
                    className="competition-cell-table-cell competition-cell-side1 title-event">{this.props.title}&nbsp;</span>

                {/*<span className="competition-cell-table-cell img-2">*/}

                {/*       <img src={this.props.awayTeam.logo} alt=""/>*/}
                {/*</span>*/}
                {/*<span className="competition-cell-table-cell competition-cell-side2">{this.props.awayTeam.name}&nbsp;*/}
                {/*</span>*/}
            </span>


        );
    }

}

Info.propTypes = {
    homeTeam: PropTypes.object.isRequired,
    awayTeam: PropTypes.object.isRequired,
    homeScore: PropTypes.object.isRequired,
    awayScore: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    showScores: state.showScores
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Info);
