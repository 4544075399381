import React from 'react'
import classnames from "classnames";
import {connect} from "react-redux";
import Streams from "../Event/Streams/Streams";
class Content extends React.Component{

    render() {
        return (
            <div>
                {/*<div className={classnames({*/}
                {/*    'd-none': this.props.tab === 'STREAMS'*/}
                {/*})}>*/}
                {/*    <Channels />*/}
                {/*</div>*/}
                <div className={classnames({
                    'd-none': this.props.tab === 'TV'
                })}>
                    <Streams hideEventWays sport={"title-event"} />
                </div>

            </div>
        );
    }

}
const mapStateToProps = state => ({
    tab: state.event.tab,
});
export default connect(mapStateToProps, {})(Content);
