import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import StatusDescription from "./StatusDescription";


class Info extends Component {
    getTeamName(name){
        const width  = window.innerWidth || document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (width <= 768){
            name = name.split(' ')
            return name[name.length -1]
        }
        return  name
    }
    score() {
        if (!this.props.showScores) {
            return 'VS';
        }
        return this.props.homeScore.current + ' - ' + this.props.awayScore.current;
    }

    render() {
        return (
            <span style={{minHeight: "35px"}} className="competition-cell-table">
                                    <span
                                        className="competition-cell-table-cell competition-cell-side1">
                                        <span className="name"> {this.getTeamName(this.props.homeTeam.name)} </span>

                                        </span>
                         <span className="logo home-team competition-cell-table-cell"><img
                             src={this.props.homeTeam.logo}
                             alt=""/></span>
                                    <span className="competition-cell-table-cell competition-cell-score">
                                        {(this.props.type !== 'notstarted' && this.props.type !== 'canceled' ) ? (this.score()) :
                                            <i className="icon-clock"/>}
                                        <StatusDescription statusDescription={this.props.statusDescription}
                                                           type={this.props.type}
                                                           startTime={this.props.startTimestamp}/>
                                    </span>
                                    <span
                                        className="competition-cell-table-cell competition-cell-side2">
                                                                                                                        <span
                                                                                                                            className="logo"><img
                                                                                                                            src={this.props.awayTeam.logo}
                                                                                                                            alt=""/></span>
                                        <span
                                            className="name"> {this.getTeamName(this.props.awayTeam.name)}</span>
                                    </span>

                                </span>
        );
    }

}

Info.propTypes = {
    homeTeam: PropTypes.object.isRequired,
    awayTeam: PropTypes.object.isRequired,
    homeScore: PropTypes.object.isRequired,
    awayScore: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    showScores: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
    showScores: state.score.showScores,
});
export default connect(mapStateToProps, {})(Info);
