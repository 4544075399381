import React, {Component} from 'react'
import Loading from "../Common/Loading";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Layout from "../Layout";
import { fetchTitleEventData, toggleLoading} from "../../actions/eventActions";
import Content from "./Content";
import {withRouter} from "react-router-dom";
import InfoPage from "./InfoPage";

class EventPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            incidents: [],
        };
    }

    fetchData(props) {
        this.props.toggleLoading(true);
        let {id, slug} = props.match.params;
        this.props.fetchTitleEventData(slug, id);
        window.scrollTo(0, 0);


    }

    componentDidMount() {
        this.fetchData(this.props);


    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.fetchData(nextProps);
        }
    }
    componentWillUnmount() {
        this.props.toggleLoading(true);
    }

    handleClick=()=> {
        this.props.history.goBack()
    };

    render() {

        return (
            <Layout>
                <div id="dialog"/>
                {
                    this.props.loading ? (
                        <div id="loading">
                            <Loading loading={this.state.loading}/>
                        </div>
                    ) : (
                        <div>


                                <div onClick={this.handleClick} style={{color:'#fff !important'}} className="btn btn-ss mb-1 mt-1"><i className="fa fa-arrow-left"/> Back</div>
                            <div className="end">
                                <InfoPage
                                      event={this.props.event}/>
                                <Content/>
                            </div>
                            <br/>


                        </div>
                    )
                }


            </Layout>
        );
    }
}

Event.propTypes = {
    fetchEventData: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    event: state.event.event,
    loading: state.event.loading,
    embed: state.base.embed,
    showStreams: state.event.showStreams
});
export default connect(mapStateToProps, {fetchTitleEventData, toggleLoading})(withRouter(EventPage));
