import React, {PureComponent} from 'react';
import Select from "react-select";
import Rows from "./Rows";
import axios from "../../modules/axios";
import SvBox from "../Common/SvBox";

class StandingTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            rand: Math.random(),
            selectedItem: {}

        }
    }

    componentDidMount() {
        axios().get(`/standing/tables/${this.props.tournamentId}/${this.props.seasonId}`)
            .then(res => {

                this.setState({
                    data: res.data.standingsTables,
                    rand: Math.random()
                }, () => {
                    if (this.state.data.length) {
                        this.setState({
                            selectedItem: {
                                value: this.state.data[this.state.data.length-1].name,
                                label: this.state.data[this.state.data.length-1].name,
                                data: this.state.data[this.state.data.length-1]
                            }
                        })
                    }
                })
            })
    }

    handleChange = selectedOption => {
        this.setState({
            selectedItem: selectedOption
        })
    };

    render() {

        return (
            <SvBox preventUpperCaseTitle title={"Standings Table"}>

                <Select
                    className={"standings-select"}
                    classNamePrefix={"standings-select"}

                    isSearchable
                    value={this.state.selectedItem}
                    onChange={this.handleChange}
                    options={this.state.data.map(item => {
                        return {
                            value: item.name,
                            label: item.name,
                            data: item
                        }
                    })}
                />
                <div id={"standings-table"}>
                    {
                        Object.keys(this.state.selectedItem).length ? (
                            <Rows
                                homeTeamId={this.props.homeTeamId}
                                awayTeamId={this.props.awayTeamId}
                                rows={this.state.selectedItem.data.tableRows}/>
                        ) : ''
                    }
                </div>


            </SvBox>
        );
    }
}

StandingTable.propTypes = {};

export default StandingTable;