import React, {Component} from 'react';
import axios from '../../modules/axios';
import SvBox from "../Common/SvBox";
import Info from "./Info";

import Calendar from "../Index/Matches/Calendar";
import Competitions from "../Index/Matches/Competitions";
import Loading from "../Common/Loading";
import formatDate from "../../utilites/formatDate";
import Layout from "../Layout";
import {Helmet} from "react-helmet";
import moment from "moment-timezone";
import {connect} from "react-redux";

class Tournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            tournament: {},
            eventDates: [],
            mainTournaments: [],
            loading: true,
            matchesLoading: true,
            hasMain: false,
            id: "",
            date: moment(),

        };
        this.fetchData = this.fetchData.bind(this);
        this.changeDate = this.changeDate.bind(this);

    }


    fetchData() {
        this.setState({
            loading: true,
            events: [],
            tournament: {},
        });
        const {id} = this.state;
        //https://darsh.sportsvideo.net/api/tournament/seasons/194/?type=soccer
        axios().get(`/tournament/seasons/${id}?type=${this.props.sport}`)
            .then(res => {
                this.setState({
                    tournament: res.data.tournament,
                    loading: false
                }, () => {
                    this.fetchMatches();
                });
            });


    }


    fetchMatches() {
        const {date, id} = this.state;
        let season = this.state.tournament.currentSeasonId;
        if (!season) season=0
        axios()
            .get(`/tournament/matches/${id}/${formatDate(date)}/${season}` )
            .then(res => {
                this.setState({
                    events: res.data,
                    matchesLoading: false
                })
            })
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    initData(props) {
        this.setState({
            id: props.match.params.id,
        }, () => {
            this.fetchData();
            this.scrollToTop()
        });


    }

    componentDidMount() {
        this.initData(this.props);
    }

    componentWillReceiveProps(props) {
        this.initData(props);
    }


    changeDate(date) {
        this.setState({
            date: date,
            matchesLoading: true
        }, () => {
            this.fetchMatches();
        });

    }


    render() {
        return (
            <Layout>
                <div className={"body-main"}>
                    <div id="loading">
                        <Loading loading={this.state.loading}/>
                    </div>
                    {!this.state.loading ? (
                        <div>
                            <Helmet>
                                <title>{this.state.tournament.name + " live streams"}</title>
                                <meta property="og:title"
                                      content={this.state.tournament.name + " live streams"}/>
                                <meta property="og:description"
                                      content={this.state.tournament.name + " fixtures, streams"}/>
                                <meta property="og:image" content={this.state.tournament.logo}/>
                            </Helmet>
                            <div onClick={this.handleClick} style={{color:'#fff !important'}} className="btn btn-ss mb-1 mt-1"><i className="fa fa-arrow-left"/> Back</div>

                            <SvBox noMarginTop>
                                <Info tournament={this.state.tournament} category={this.state.category}/>
                            </SvBox>
                            <br/>
                            <div className="row">
                                <div className="col-md-12">
                                    <SvBox>
                                        <Calendar onChange={this.changeDate} date={this.state.date}/>
                                    </SvBox>
                                    <div id="loading" className="text-center">
                                        <br/>
                                        <Loading loading={this.state.matchesLoading}/>
                                    </div>
                                    {this.state.matchesLoading === false ? (
                                        this.state.events.length ? (
                                            <SvBox title="Matches"><Competitions
                                                competitions={this.state.events}/></SvBox>) : (
                                            <div>
                                                <h3 className="text-center">There are no matches today</h3>
                                            </div>

                                        )
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    ) : ''}
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = state=>({
   sport:state.sport.slug
});
export default connect(mapStateToProps,{})(Tournament);
