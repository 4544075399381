import React, {PureComponent} from 'react';
import LatestMatchItem from "./LatestMatchItem";
import axios from "../../modules/axios";
import store from "../../store";
import SvBox from "../Common/SvBox";

class LatestMatches extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            home: [],
            away: []
        }
    }

    componentDidMount() {
        axios()
            .get(`/event/latest-matches/${this.props.event.id}?type=${store.getState().sport.slug}`)
            .then(res => {
                this.setState({
                    home: res.data.home,
                    away: res.data.away,
                })
            })
    }

    render() {
        const event = this.props.event;
        return (
            <div className="row">
                <div className="col-md-6">
                    <SvBox  image={event.homeTeam.logo} sub={(<small>Last Five Games</small>)} preventUpperCaseTitle
                           title={event.homeTeam.name}>
                        <div className={"home"}>
                            {this.state.home.map((item, key) => {
                                return (<LatestMatchItem  key={key} event={item} teamId={event.homeTeam.id}/>)
                            })}
                        </div>

                    </SvBox>
                </div>
                <div className="col-md-6">
                    <SvBox  image={event.awayTeam.logo} sub={(<small>Last Five Games</small>)} preventUpperCaseTitle
                           title={event.awayTeam.name}>
                        <div className={"away"}>
                            {this.state.away.map((item, key) => {
                                return (<LatestMatchItem  key={key} event={item} teamId={event.awayTeam.id}/>)
                            })}
                        </div>
                    </SvBox>
                </div>
            </div>
        );
    }
}

LatestMatches.propTypes = {};

export default LatestMatches;