import React from 'react'
import {connect} from "react-redux";
import {setSelectedTeams} from "../../actions/sportActions";

class Teams extends React.PureComponent {
    selectTeamToFilter = (team) => {
        const item = {value: team.id, label: team.name};
        let selectedTeams = this.props.selectedTeams;
        let hasItem = false;
        for (let i=0;i<selectedTeams.length;i++){
            if (selectedTeams[i].value===item.value){
                hasItem = true;
                break;
            }
        }
        if (hasItem){
            selectedTeams = selectedTeams.filter((a)=>{
                return a.value !== item.value;
            });
        }else {
             selectedTeams.push(item)
        }
        this.props.setSelectedTeams(selectedTeams)

    };

    render() {
        const selectTeamToFilter = this.selectTeamToFilter;
        return (
            <div id="header-teams" className="d-none d-lg-block">
                {this.props.teams.map(function (team, key) {
                    return (
                        <a href="#" onClick={() => selectTeamToFilter(team)} key={key} className="team">
                            <img loading="lazy" src={team.logo} alt={team.name}/>
                        </a>
                    )
                })}
            </div>
        );
    }

}

const mapStateToProps = state => ({
    teams: state.sport.teams,
    selectedTeams: state.sport.selectedTeams,
    random: state.sport.random,
});

export default connect(mapStateToProps, {setSelectedTeams})(Teams)
