import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Info extends Component {
    render() {
        return (
            <div className="d-flex">
                    <div style={{textAlign: "center",padding:'5px'}}>
                        <img style={{width: "85px"}} src={this.props.team.logo} alt=""/>
                    </div>
                <div     id="tournament-info" style={{padding:'5px'}}>
                    <h4 className="name">{this.props.team.name}</h4>
                    <h6 className="description">Watch every single {this.props.team.name} matches live streaming online for free</h6>

                </div>
            </div>
        );
    }
}

Info.propTypes = {
    team: PropTypes.object.isRequired,
};
export default Info;
