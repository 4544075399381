import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {Link} from "react-router-dom";

class Rows extends PureComponent {
    render() {
        return (
            <div className="table-responsive">
                <table className="table table-striped ">
                    <thead>
                    <tr>
                        <th></th>
                        <th>P</th>
                        <th>W</th>
                        <th>L</th>
                        <th>GB</th>
                        <th>Str</th>
                        <th>PCT</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.rows.map((item, key) => {
                        return (
                            <tr className={classnames({
                                'home-row': this.props.homeTeamId == item.team.id,
                                'away-row': this.props.awayTeamId == item.team.id,
                            })} key={key}>
                                <td>
                                    <span className={"mr-2"}>{item.position} </span>
                                    <span>
                                        <Link to={`/team/${item.team.slug}/${item.team.id}`}>
                                            <span className={"mr-2"}><img src={item.team.logo} width={30} height={30}
                                                                          alt=""/></span>
                                        <span>{item.team.name}</span>
                                        </Link>
                                    </span>
                                </td>
                                <td>{item.matches}</td>
                                <td>{item.wins}</td>
                                <td>{item.losses}</td>
                                <td>{item.gamesBehind}</td>
                                <td>{item.streak}</td>
                                <td>{item.percentage}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

Rows.propTypes = {};

export default Rows;