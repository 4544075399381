import React,{Component} from 'react';
import PropTypes from 'prop-types';

class DayInfo extends Component{
    render() {
        return (
            <div>
                <span className="day">{this.props.name}</span>
            </div>
        );
    }
}
DayInfo.propTypes = {
    name:PropTypes.string.isRequired,
};
export default DayInfo;