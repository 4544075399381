import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Competitions from "./Competitions";
import Events from "../../TitleEvents/Events";

class TopTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentWillMount() {
        this.setState({
            isOpen: this.props.tournament.open
        })
    }

    render() {
        const isOpen = this.state.isOpen;
        let titleEvents =  this.props.tournament.titleEvents;
        if (!Array.isArray(titleEvents))
            titleEvents = [];
        return (
            <div className="top-tournament ">

                <a
                    onClick={() => this.setState({isOpen: !isOpen})}

                    style={{
                        padding: '4px 0px',
                        display: 'block',
                        cursor: 'pointer',
                    }}> <img src={this.props.tournament.logo} alt=""/>
                    <span className="league-name">{this.props.tournament.uniqueName}</span>
                </a>
                <Events  events={titleEvents}/>
                <Competitions competitions={this.props.tournament.events}/>
            </div>
        );
    }

}

TopTournament.propTypes = {
    tournament: PropTypes.object.isRequired
};
export default TopTournament;
