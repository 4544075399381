import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeEventTabData} from "../../actions/eventActions";
import PropTypes from 'prop-types';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.scrollToItem = this.scrollToItem.bind(this);
    }

    scrollToItem(item) {
        var diff = (item.offsetTop - window.scrollY) / 8
        if (Math.abs(diff) > 1) {
            window.scrollTo(0, (window.scrollY + diff))
            clearTimeout(window._TO);
            window._TO = setTimeout(this.scrollToItem, 30, item)
        } else {
            window.scrollTo(0, item.offsetTop)
        }
    }

    handleSelect(key) {
        this.props.changeEventTabData(key);
    }

    componentWillUnmount() {
        this.props.changeEventTabData("TV");
    }

    render() {
        const key = this.props.tab;
        return (
            <>
                <ul className="nav nav-pills">
                    <li role="presentation" className="active">
                        <a href="#streams" role="button">
                            <i className="icon-youtube"/> <span>Live Streams</span>
                        </a>
                    </li>
                </ul>
            </>
        );
    }
}

Tabs.propTypes = {
    changeEventTabData: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    sport: PropTypes.string.isRequired
};
const mapStateToProps = state => ({
    tab: state.event.tab,
});


export default connect(mapStateToProps, {changeEventTabData})(Tabs);
