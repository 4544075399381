import React, {Component} from 'react';
import axios from '../../modules/axios'
import Calendar from "./Matches/Calendar";
import TopMatches from "./Matches/TopMatches";
import Loading from "../Common/Loading";
import SvBox from "../Common/SvBox";
import moment from 'moment-timezone'
import {checkLeagues, setLeagues} from "../../actions/eventActions";
import store from "../../store";
import {connect} from "react-redux";
import StandingTable from "../Standings/StandingTable";

class Matches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tournaments: [],
            date: moment(),
            loading: true,
            top: [],
            data: [],
            live: [],
            showLive: false,
        };

        this.changeDate = this.changeDate.bind(this);
    }

    componentDidMount() {
        setTimeout(function () {
            this.fetchMatches();
        }.bind(this), 1)

    }

    changeDate(date) {
        this.setState({
            date: date,
            loading: true
        }, () => {
            this.fetchMatches();
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            top: store.dispatch(checkLeagues()),
        })
    }


    fetchMatches() {
        const date = this.state.date.format('YYYY-MM-DD');
        // var d = new Date();
        // var n = d.getTimezoneOffset();
        axios()
            .get(`https://scdn.dev${this.props.endpoint}${date}`)
            .then(res => {
                let data = res.data;
                if (data.hasOwnProperty('top'))
                    data = data.top;
                this.props.setLeagues(data);
                this.setState({
                    top: store.dispatch(checkLeagues()),
                    loading: false,
                    showLive: false
                })
            }).catch(err => {

        })
    }


    render() {
        return (
            <div>
                <SvBox>
                    <div className="row">

                        <div className="col-md-12">
                            <Calendar onChange={this.changeDate} date={this.state.date}/>
                        </div>
                    </div>
                </SvBox>
                <div style={{padding: '5px'}} id="loading" className="text-center">
                    <Loading loading={this.state.loading}/>
                </div>
                {this.state.loading === false ?
                    (
                        !this.state.showLive ? (
                            <TopMatches tournaments={this.state.top}/>
                        ) : (<TopMatches live tournaments={this.state.live}/>)
                    ) : ''}
                {process.env.REACT_APP_TOURNAMENT_ID && process.env.REACT_APP_SEASON_ID ? (
                    <StandingTable
                        tournamentId={process.env.REACT_APP_TOURNAMENT_ID}
                        seasonId={process.env.REACT_APP_SEASON_ID}
                    />
                ) : ''}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    endpoint: state.sport.endpoint,
    random: state.sport.random,
    selectedTeams: state.sport.selectedTeams,

});
export default connect(mapStateToProps, {setLeagues})(Matches);
