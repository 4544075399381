import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvBox from "../Common/SvBox";
import {connect} from "react-redux";
import classnames from 'classnames';
import Tabs from "../Event/Tabs";

class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goalIncidents: [],
            penaltyIncidents: [],
            sticky: false
        };
    }

    render() {
        const props = this.props;
        return (
            <div>
                <SvBox noRadius
                       noMarginTop image={props.event.tournament.logo}
                       title={props.event.tournament.name}>

                    <div className="match-view-headT text-center">
                        <h5>                        {props.event.title}
                        </h5>
                    </div>
                    <br/>
                </SvBox>
                <div className="clearfix"/>
                <div id="event-sticky-info">

                    <div className={classnames({
                        "hidden": !this.props.eventStickyInfo && this.state.sticky,
                    })}>
                        <Tabs sport={"title-event"} type={this.props.event.type}/>
                    </div>
                </div>
            </div>
        );
    }
}

Info.propTypes = {
    event: PropTypes.object.isRequired,
    incidents: PropTypes.array.isRequired,
    showScores: PropTypes.bool.isRequired,
    search: PropTypes.bool.isRequired

};
const mapStateToProps = state => ({
    showScores: state.score.showScores,
    search: state.base.search,
    eventStickyInfo: state.base.eventStickyInfo,
    sport: state.sport.slug,
});
export default connect(mapStateToProps, {})(Info);
