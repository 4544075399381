import React, {Component, Suspense} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import './styles/app.scss';
import {Provider} from 'react-redux';
import store from './store';
import {agreeCookie, changeTheme, enableEmbed} from "./actions/baseActions";
import * as queryString from "query-string";
import {fetchLeagueSeasons, setSportData} from "./actions/sportActions";
import {changeScoreView} from "./actions/scoreActions";
import Home from './Components/Home';
import Event from './Components/Event/Event';
import Team from './Components/Team/Team';
import Tournament from './Components/Tournament/Tournament';
import DCMA from './Components/Main/DCMA';
import PrivacyPolicy from './Components/Main/PrivacyPolicy';
import ContactUs from './Components/Main/ContactUs';
import SignUp from './Components/Main/SignUp';
import EventPage from './Components/TitleEvents/EventPage';


if (localStorage.cookie)
    store.dispatch(agreeCookie());
if (localStorage.showScores) {
    store.dispatch(changeScoreView(localStorage.showScores === 'true'));
}


const sports = [
    "ice-hockey",
    "basketball",
    "american-football",
    "soccer",
    "baseball",
    "cricket",
    "motorsport",
];

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: true
        };
    }

    componentWillMount() {

        const parsed = queryString.parse(window.location.search);
        const sport = process.env.REACT_APP_SPORT;
        if (sport) {
            store.dispatch(setSportData(sport));

        } else if (parsed.sport) {
            if (sports.indexOf(parsed.sport) !== -1) {
                store.dispatch(setSportData(parsed.sport));
            } else {
                this.setState({render: false})
            }
        }

        if (localStorage.darkTheme) {
            store.dispatch(changeTheme(localStorage.darkTheme === 'true'));
        } else {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                store.dispatch(changeTheme(true));
            } else {
                store.dispatch(changeTheme(false));
            }

        }
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed.embed === "true" || process.env.REACT_APP_EMBED === "true") {
            store.dispatch(enableEmbed())
        }
        store.dispatch(fetchLeagueSeasons());
    }

    render() {
        if (!this.state.render)
            return (<div/>);
        return (
            <Suspense fallback={<div/>}>
                <Provider store={store}>
                    <Router>
                        <div>
                            <Switch>


                                <Route exact path="/" component={Home}/>
                                <Route exact path={`/${process.env.REACT_APP_EVENT_PREFIX}/:slug/:id`}
                                       component={Event}/>
                                <Route exact path="/title-event/:slug/:id" component={EventPage}/>
                                <Route exact path="/tournament/:slug/:id" component={Tournament}/>
                                <Route exact path="/team/:slug/:id" component={Team}/>
                                <Route exact path="/dcma" component={DCMA}/>
                                <Route exact path="/privacy" component={PrivacyPolicy}/>
                                <Route exact path="/contact-us" component={ContactUs}/>
                                <Route exact path="/stay-in-touch" component={SignUp}/>
                                <Route exact path="/sport/soccer" component={Home}/>
                                <Route exact component={Home}/>
                            </Switch>
                        </div>
                    </Router>
                </Provider>
            </Suspense>

        )
            ;
    }
}

export default App;
