import {combineReducers} from 'redux';
import scoreReducer from './scoreReducer';
import highlightReducer from './highlightReducer';
import baseReducer from './baseReducer';
import eventReducer from './eventReducer';
import sportReducer from './sportReducer';

export default combineReducers({
    score: scoreReducer,
    highlight: highlightReducer,
    base: baseReducer,
    event:eventReducer,
    sport:sportReducer,
})