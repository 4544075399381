import React, {Component} from 'react';
import PropTypes from 'prop-types';
import convertStartTime from "../../utilites/convertStartTime";

class StartTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 0
        }
    }

    daysLeft() {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var date2 = new Date();
        var date1 = new Date(this.props.timestamp * 1000);
        if (date1.getDay() === date2.getDay())
            return 0;
        const utc2 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utc1 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    startTimer() {
            setInterval(()=>{
                let seconds = this.state.seconds;
                if (seconds!==0)
                    seconds--;
                this.setState({
                    seconds: seconds
                })
            },1000)
    }

    componentDidMount() {
        var date2 = new Date();
        var date1 = new Date(this.props.timestamp * 1000);

        var dif = date1.getTime() - date2.getTime();

        var Seconds_from_T1_to_T2 = dif / 1000;
        var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);

        this.setState({
            seconds: parseInt(Seconds_Between_Dates)
        },()=>{
            if (Seconds_Between_Dates>0)
                this.startTimer();
        });

    }
    twoDigitNumber(str){
        str = str.toString();
        if (str.length===1)
            return "0"+str;
        return str;
    }

    render() {
        const countDayLeft = this.daysLeft();
        let timeDiff = this.state.seconds;
        var hours = Math.floor(timeDiff / 3600);
        timeDiff %= 3600;
        var minutes = Math.floor(timeDiff / 60);
        var seconds = parseInt(timeDiff % 60);
        return countDayLeft && hours > 24 ? (
                    <div>
                        <div className="days-left">IN {countDayLeft} DAYS</div>
                        <div className="start-time">{convertStartTime(this.props.timestamp)}</div>

                    </div>) : (
                            <div className="start-time timer" >{this.twoDigitNumber(hours)+":"+this.twoDigitNumber(minutes)+":"+this.twoDigitNumber(seconds)}</div>
        );

    }

}

StartTime.propTypes = {
    timestamp: PropTypes.number.isRequired
};
export default StartTime;
