import React, {Component} from 'react';
import SvBox from "../Common/SvBox";
import Layout from "../Layout";

class DCMA extends Component {
    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    componentDidMount() {
        this.scrollToTop();
    }

    componentWillReceiveProps() {
        this.scrollToTop();

    }

    render() {
        return (
            <Layout>
                <SvBox largeTitle name="DCMA">
                    <p>
                        All the video content found on the SportsVideo is not hosted on our servers nor is created or
                        uploaded by us. SportsVideo simply acts as a search engine that finds videos from websites like
                        YouTube, DailyMotion, Metacafe and other video portals. SportsVideo is not responsible for
                        external
                        websites content. If you find that some of the content violates your rights you may request for
                        that
                        content to be brought down at the host that is responsible for the content. That will ensure
                        that
                        the content is removed from SportsVideo.net and other search engines that may have indexed the
                        content.
                    </p>
                </SvBox>
            </Layout>
        );
    }

}

export default DCMA;