import {
    SET_EMBED_LOADING,
    SET_EVENT_EMBED, SET_VIDEO_TYPE,
    SHOW_HIGHLIGHT,
    STORE_EVENT_EMBED,
    STORE_HIGHLIGHTS,
    STORE_OTHER_NATCHES
} from "../actions/types";

const initialState = {
    highlight: {},
    eventEmbed: {},
    otherMatches: {},
    videoType: "goals",
    embedLoading: true,
    eventId: null,
    highlights: []

};
export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_HIGHLIGHT:
            return {
                ...state,
                highlight: action.payload
            };
        case STORE_HIGHLIGHTS:
            return {
                ...state,
                highlights: action.payload
            };
        case SET_EVENT_EMBED:
            return {
                ...state,
                eventId: action.payload
            };
        case STORE_OTHER_NATCHES:
            return {
                ...state,
                otherMatches: action.payload
            };
        case STORE_EVENT_EMBED:
            return {
                ...state,
                eventEmbed: action.payload
            };
        case SET_VIDEO_TYPE:
            return {
                ...state,
                videoType: action.payload
            };
        case SET_EMBED_LOADING:
            return {
                ...state,
                embedLoading: action.payload
            };
        default:
            return state;
    }
}