import React, {Component} from 'react';
import Matches from "./Index/Matches";
import Layout from "./Layout";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
class Home extends Component {
    render() {
        return (
            <Layout >
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                    <meta property="og:title"
                          content={process.env.REACT_APP_TITLE}/>

                    <meta property="description"
                          content={process.env.REACT_APP_META_DESCRIPTION}/>
                    <meta property="og:description"
                          content={process.env.REACT_APP_META_DESCRIPTION}/>

                </Helmet>

                <Matches/>
            </Layout>
        );
    }

}


const mapStateToProps = state => ({
    embed: state.base.embed,
    color: state.sport.color,
});
export default connect(mapStateToProps, {})(Home);
