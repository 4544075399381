import React,{Component} from 'react';
class Score extends Component{

    render() {
        return (
            this.props.score!==null ?  (<span className="score">{this.props.score}</span>) : ''
        );
    }

}
export default Score;