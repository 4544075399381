import React  from 'react';
import PropTypes from 'prop-types';
import {ClipLoader} from 'react-spinners';
import {css} from 'react-emotion';
import {connect} from 'react-redux';

class Loading extends React.PureComponent {

    render() {
        const override = css`
    display: block;
    margin: 0 auto;
`;
        return (
            <ClipLoader
                className={override}
                style={{margin: "auto"}}
                sizeUnit={"px"}
                size={60}
                color={this.props.color}
                loading={this.props.loading}
            />
        );
    }

}

Loading.propTypes = {
    loading: PropTypes.bool.isRequired,
    darkTheme:PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
    darkTheme: state.base.darkTheme,
    color: state.sport.color,
});
export default connect(mapStateToProps, {})(Loading);
