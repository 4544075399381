import React, {PureComponent} from 'react';

class LatestMatchItem extends PureComponent {
    isWinner = () => {
        if (this.props.event.homeTeam.id == this.props.teamId && this.props.event.awayScore.current <  this.props.event.homeScore.current) {
            return true;
        }

        if (this.props.event.awayTeam.id == this.props.teamId && this.props.event.awayScore.current >  this.props.event.homeScore.current ) {
            return true;
        }
        return false;
    }

    getCompetitorName = () => {
        if (this.props.event.homeTeam.id == this.props.teamId ) {
            return this.props.event.awayTeam.name;
        }

        if (this.props.event.awayTeam.id == this.props.teamId ) {
            return this.props.event.homeTeam.name;

        }
        return '';
    }
    getCompetitorSign = () => {
        if (this.props.event.homeTeam.id == this.props.teamId ) {
            return '@';
        }

        if (this.props.event.awayTeam.id == this.props.teamId ) {
            return 'VS'

        }
        return '';
    }
    getHomeScoreClass=()=>{
        if (this.props.event.homeTeam.id == this.props.teamId ) {
            return 'home-score colored';
        }

        if (this.props.event.awayTeam.id == this.props.teamId ) {
            return 'home-score';
        }
    }
    getAwayScoreClass=()=>{
        if (this.props.event.homeTeam.id == this.props.teamId ) {
            return 'away-score ';
        }

        if (this.props.event.awayTeam.id == this.props.teamId ) {
            return 'away-score colored';
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-between latest-match-item my-1">
                <div>{this.isWinner() ? 'W' : 'L'}</div>
                <div className={"flex-1 text-center"}>{this.getCompetitorSign()} {this.getCompetitorName()}</div>
                <div>
                    <span className={this.getHomeScoreClass()}>{this.props.event.homeScore.current}</span> - <span className={this.getAwayScoreClass()}>{this.props.event.awayScore.current}</span>
                </div>
            </div>
        );
    }
}

LatestMatchItem.propTypes = {};

export default LatestMatchItem;