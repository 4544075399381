import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvBox from '../../Common/SvBox'
import TopTournament from "./TopTournament";
import ShowScores from "../../Sidebar/ShowScores";
import {connect} from "react-redux";

class TopMatches extends Component {


    render() {
        return (
            <SvBox right={<ShowScores/>} name="" title={this.props.sportSlug==="soccer" ? "Top Matches" : "Top Games"}>
                {this.props.tournaments.map((tournament, i) => {
                    return (<TopTournament key={i} tournament={tournament}/>);
                })}
                {!this.props.tournaments.length && this.props.live ? (
                    <div style={{fontWeight: "500"}} className="text-center">No live match right now please check
                        later.</div>) : ''}
                {!this.props.tournaments.length && !this.props.live ? (
                    <div style={{fontWeight: "500"}} className="text-center">No match please check later.</div>) : ''}
            </SvBox>

        );
    }
}

TopMatches.propTypes = {
    tournaments: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
    sportSlug: state.sport.slug
});
export default connect(mapStateToProps, {})(TopMatches);
