import {
    SET_MAIN_SEASON, SET_SELECTED_TEAMS,
    SET_SPORT_DATA, SET_TEAMS
} from './types';
import store from "../store";
import axios from "../modules/axios";
import moment from "moment-timezone";

export const setSportData = (sport) => dispatch => {
    const sportData = {
        basketball: {
            name: "nba",
            timezone: "America/New_York",
            slug: sport,
            endpoint: "/api/nba-tournaments?date=",
            color: "#c80f2f",
            uniqueID: 132,
            mainID: 320,
            founder: "/r/NBAStreams",
            logo: "NBASTREAMS",
            logoType: "text",
            headerAndFooterColor: "#fff",
            backgroundCSS: "#1d4289",
            footerLogo: "/images/logos/nba.png",
            cssStyles: {
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat'
            },
            themes: {
                "dark": {
                    headerAndFooterColor: "#fff",
                    backgroundCSS: "#1f1f1f",
                    color: "#c80f2f",
                    cssStyles: {
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat'
                    },
                },
                "light": {
                    headerAndFooterColor: "#fff",
                    backgroundCSS: "#1d4289",
                    color: "#c80f2f",
                    cssStyles: {
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat'
                    },
                },
            }
        },
        "american-football": {
            slug: sport,
            name: "nfl",
            timezone: "America/New_York",
            endpoint: "/api/nfl-tournaments-week?date=",
            logo: "NFLSTREAMS",
            logoHeight: "75px",
            uniqueID: 9464,
            headerAndFooterColor: "#fff",
            color: "#d50c0a",
            backgroundCSS: "-webkit-radial-gradient(50% 190%,circle cover,#43CC33 0%,#257A09 50%,#43CC33 100%) #6CF354",
            mainID: 90,
            logoType: "text",
            footerLogo: "/images/logos/nfl.png",
            founder: "/r/NFLStreams",
            themes:{
                dark:{
                    headerAndFooterColor: "#fff",
                    color: "#d50c0a",
                    backgroundCSS: "#1f1f1f",
                },
                light:{
                    headerAndFooterColor: "#fff",
                    color: "#d50c0a",
                    backgroundCSS: "-webkit-radial-gradient(50% 190%,circle cover,#43CC33 0%,#257A09 50%,#43CC33 100%) #6CF354",
                }
            }
        },
        "mma": {
            slug: sport,
            name: "mma",
            timezone: "America/New_York",
            endpoint: "/api/mma-tournaments?date=",
            logo: "MMASTREAMS",
            logoHeight: "75px",
            uniqueID: 9464,
            headerAndFooterColor: "#fff",
            color: "#c62828",
            backgroundCSS: "#c62828",
            mainID: 90,
            logoType: "text",
            footerLogo: "",
            founder: "",
            themes:{
                dark:{
                    headerAndFooterColor: "#fff",
                    color: "#d50c0a",
                    backgroundCSS: "#1f1f1f",
                },
                light:{
                    headerAndFooterColor: "#fff",
                    color: "#d50c0a",
                    backgroundCSS: "#c62828",
                }
            }
        },
        "boxing": {
            slug: sport,
            name: "boxing",
            timezone: "America/New_York",
            endpoint: "/api/boxing-tournaments?date=",
            logo: "MMASTREAMS",
            logoHeight: "75px",
            uniqueID: 9464,
            headerAndFooterColor: "#d30002",
            color: "#d30002",
            backgroundCSS: "#080808",
            mainID: 90,
            logoType: "text",
            footerLogo: "",
            founder: "",
            themes:{
                dark:{
                    headerAndFooterColor: "#d30002",
                    color: "#080808",
                    backgroundCSS: "#080808",
                },
                light:{
                    headerAndFooterColor: "#d30002",
                    color: "#d30002",
                    backgroundCSS: "#080808",
                }
            }
        },
        "ice-hockey": {
            slug: sport,
            name: "nhl",
            timezone: "America/New_York",
            endpoint: "/api/nhl-tournaments?date=",
            logo: "NHLSTREAMS",
            color: "#4db2ec",
            headerAndFooterColor: "#fff",
            backgroundCSS: "#000",
            uniqueID: 234,
            logoType: "text",
            mainID: 95,
            founder: "",
            themes: {
                dark: {
                    color: "#4db2ec",
                    headerAndFooterColor: "#fff",
                    backgroundCSS: "#1f1f1f",
                },
                light: {
                    color: "#4db2ec",
                    headerAndFooterColor: "#fff",
                    backgroundCSS: "#000",
                }
            }

        },
        "soccer": {
            slug: sport,
            name: "soccer",
            endpoint: "/new-api/matches?date=",
            color: "#000",
            logoType:"image",
            logo:"/images/footybite-banner.png",
            themes: {
                dark: {
                    backgroundCSS: "#1f1f1f",
                    color: "#bf0c3e",
                    headerAndFooterColor: "#222d34",
                },
                light: {
                    color: "#bf0c3e",
                    backgroundCSS: "#b5e2fc",
                    headerAndFooterColor: "#222d34",
                }
            }
        },
        "baseball": {
            slug: sport,
            name: "mlb",
            endpoint: "/api/mlb-tournaments?date=",
            color: "#bf0c3e",
            backgroundCSS: "#041e42",
            headerAndFooterColor: "#fff",
            footerLogo: "/images/logos/mlb.jpg",
            logoType: "text",
            logo: "MLBSTREAMS",
            founder: "/r/MLBStreams",
            uniqueID: 11205,
            mainID: 1437,
            themes: {
                dark: {
                    backgroundCSS: "#1f1f1f",
                    color: "#bf0c3e",
                    headerAndFooterColor: "#fff",
                },
                light: {
                    color: "#bf0c3e",
                    backgroundCSS: "#041e42",
                    headerAndFooterColor: "#fff",
                }
            }
        },
        "cricket": {
            slug: sport,
            name: "cricket",
            endpoint: "/api/cricket-tournaments?date=",
            color: "#4db2ec",
            logoType: "text",
            backgroundCSS: "#60a630",
            headerAndFooterColor: "#fff",
            logo: "CRICKET STREAMS",
        },
        "motorsport": {
            slug: sport,
            name: "motorsport",
            endpoint: "/api/motorsport-tournaments?date=",
            color: "rgb(230, 27, 0)"
        },
    };
    if (sportData[sport].timezone) {
        moment.tz.setDefault("America/New_York");
    }
    dispatch({
        type: SET_SPORT_DATA,
        payload: sportData[sport]
    })
};

export const fetchLeagueSeasons = () => dispatch => {
    const mainID = store.getState().sport.sportData.mainID;
    if (!mainID)
        return;
    axios().get(`/tournament/seasons/${mainID}`)
        .then(res => {
            dispatch({
                type: SET_MAIN_SEASON,
                payload: res.data.seasons[0]
            })
            store.dispatch(fetchLeagueTeams())
        })
        .catch(() => {

        });
};
export const fetchLeagueTeams = () => dispatch => {
    const {mainID, uniqueID} = store.getState().sport.sportData;
    const seasonID = store.getState().sport.mainSeason.id
    axios().get(`/tournament/teams/${mainID}/${seasonID}/${uniqueID}`)
        .then(res => {
            dispatch({
                type: SET_TEAMS,
                payload: res.data
            })
        })
        .catch(() => {

        });
};
export const setSelectedTeams = (teams) => dispatch => {
    dispatch({
        type: SET_SELECTED_TEAMS,
        payload: teams
    })
};
