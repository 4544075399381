import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Navbar,Nav} from "react-bootstrap";
import axios from "../../modules/axios";

const ourSites = [
    {
        "name":"Footybite",
        "link":"https://footybite.cc"
    },
    {
        "name":"NFLbite",
        "link":"https://nflbite.com/"
    },
    {
        "name":"NBAbite",
        "link":"https://nbabite.com/"
    },
    {
        "name":"NHLbite",
        "link":"https://nhlbite.com/"
    },
    {
        "name":"MLBshow",
        "link":"https://mlbshow.com/"
    },
    {
        "name":"totalsportek",
        "link":"https://totalsportek.to/live"
    },
    {
        "name":"hesgoal",
        "link":"https://hesgoal.pro"
    },
];
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tournaments: [],
            ourSites: []
        };
    }


    componentWillMount() {

        axios()
            .get('/backlink/'+process.env.REACT_APP_SITE_UNIQUE_NAME+'/FOOTER')
            .then(res=>{
                this.setState({
                    ourSites:res.data
                })
            })
    }

    render() {
        const {sportData,styles}  = this.props;
        const sports = {
            'ice-hockey': 'NHL',
            'american-football': 'NFL',
            'basketball': 'NBA',
            'baseball': 'MLB',
            'mma': 'MMA',
        }
        const name = sports[process.env.REACT_APP_SPORT]
        return (

            <div className="wrapper" style={{background:process.env.REACT_APP_BACKGROUND_CSS ? process.env.REACT_APP_BACKGROUND_CSS : styles.backgroundCSS}}>
                <footer >
                    <div className="container">
                        <div className="row">
                            {/*<Col md={12}>*/}
                            {/*    <ul className="footer-teams">*/}
                            {/*        {this.props.topTeams.map((item, i) => {*/}
                            {/*            return (<li key={i}>*/}
                            {/*                <Link to={"/team/" + item.slug + "/" + item.id}>*/}
                            {/*                    <span><img src={item.logo} alt=""/></span>*/}
                            {/*                    <span className="name">{item.name}</span>*/}
                            {/*                </Link>*/}
                            {/*            </li>);*/}
                            {/*        })}*/}
                            {/*    </ul>*/}
                            {/*</Col>*/}

                            <div className="col-12">
                                <h1>Reddit {name} Streams </h1>
                                <p>
                                    Watch {name} streams online on any device. You can watch the latest footage of every game, every week, as well as highlights and more.
                                </p>
                                <p>
                                    The {name} is the most watched professional sport in the United States. With Reddit {name} live streams, fans have more access to it.
                                </p>
                                <p>Stream all {name} games live and on-demand. Watch the latest games and highlights for free. No cable or satellite subscriptions needed.</p>
                                <p>
                                    Watch {name} games live on your PC, Mac, or mobile device. The {name} offers live streaming of every game so you can catch the action as it happens.
                                </p>
                                <Navbar expand={"sm"}>
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="justify-content-center"  style={{width:'100%'}}>
                                            {this.state.ourSites.map((item, key) => {
                                                return (
                                                    <Nav.Link  target="_blank" key={key}  href={`https://${item.link}`}>{item.name}</Nav.Link>
                                                )
                                            })}
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                                <hr/>
                                <div className="text-center">
                                    <img style={{width:"10%",marginBottom:'10px'}} src={sportData.footerLogo} className="img-fluid" alt=""/>
                                </div>

                                <p style={{color: "var(--main-color)", textAlign: "justify"}}>
                                    <div className="clearfix"/>
                                    <div>
                                        <div className="row justify-content-center">
                                            <div className=" col-md-6 col-md-offset-3">
                                                <p style={{color:process.env.REACT_APP_HEADER_AND_FOOTER_COLOR ? process.env.REACT_APP_HEADER_AND_FOOTER_COLOR : styles.headerAndFooterColor}}  className="text-center">
                                                    Copyrights © {new Date().getFullYear()} {(new URL(window.location.href).hostname)}. All
                                                    rights
                                                    reserved.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

Footer.propTypes = {
};
const mapStateToProps = state => ({
    sportData:state.sport.sportData,
    styles:state.base.styles,
});

export default connect(mapStateToProps)(Footer);
