import {
    CHANGE_THEME,
    AGREE_COOKIES,
    SHOW_SEARCH,
    HIDDEN_SEARCH,
    FETCH_TOP_TEAMS,
    FETCH_COUNTRIES,
    FETCH_POPULAR_COUNTRIES, TOGGLE_LEFT_SIDEBAR, TOGGLE_STICKY_INFO, ENABLE_EMBED, SET_STYLES
} from './types';
import axios from '../modules/axios'
import store from "../store";

export const changeTheme = (darkTheme) => dispatch => {
    localStorage.setItem('darkTheme', darkTheme);
    const theme = darkTheme === true ? 'dark' : 'light';
    document.getElementsByTagName("body")[0].className = theme;
    store.dispatch(setStyles(theme))
    dispatch({
        type: CHANGE_THEME,
        payload: darkTheme
    })
};
export const agreeCookie = () => dispatch => {
    localStorage.setItem('cookie', true);
    dispatch({
        type: AGREE_COOKIES,
        payload: true
    })
};

export const enableEmbed = () => dispatch => {
    dispatch({
        type: ENABLE_EMBED,
        payload: true
    })
};

export const showSearch = () => dispatch => {
    const element = document.getElementById('search-overlay');
    element.style.display = "block";
    dispatch({
        type: SHOW_SEARCH,
        payload: true
    })
};
export const hiddenSearch = () => dispatch => {
    const element = document.getElementById('search-overlay');
    element.style.display = "none";

    dispatch({
        type: HIDDEN_SEARCH,
        payload: false
    })
};
export const fetchTopTeams = () => dispatch => {
    axios().get("/soccerstreams-top-teams")
        .then(res => {
            dispatch({
                type: FETCH_TOP_TEAMS,
                payload: res.data
            })
        })

};
export const fetchCountries = () => dispatch => {
    axios().get('/football/countries')
        .then(res => {
            dispatch({
                type: FETCH_COUNTRIES,
                payload: res.data
            })
        })
};
export const fetchPopularCountries = () => dispatch => {
    axios().get('/football/popular-countries')
        .then(res => {
            dispatch({
                type: FETCH_POPULAR_COUNTRIES,
                payload: res.data
            })
        })
};
export const toggleLeftSidebar = (isOpen) => dispatch => {
    if (!isOpen){
        document.getElementById('sidebar').style.height='0px';
    }
    dispatch({
        type: TOGGLE_LEFT_SIDEBAR,
        payload: isOpen
    })
};
export const toggleStickyEventInfo = (isOpen) => dispatch => {
    localStorage.setItem('stickyEventInfo', isOpen);
    dispatch({
        type: TOGGLE_STICKY_INFO,
        payload: isOpen
    })
};

export const setStyles = (theme) => dispatch => {
    const themes = store.getState().sport.sportData.themes
    dispatch({
        type: SET_STYLES,
        payload: themes[theme]
    })
};
