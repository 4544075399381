import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {changeScoreView} from "../../actions/scoreActions";
import Toggle from 'react-toggle-component'

class ShowScores extends Component {
    constructor(props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
    }

    onToggle(checked) {
        this.props.changeScoreView(checked);
    }

    render() {
        return (
            <div className="text-center">
                <Toggle id="show-scores-toggle" checked={this.props.showScores} style={{marginTop: "10px"}}  label="Scores" onToggle={this.onToggle}/>
                <div className="clearfix"/>
                <br/>
            </div>
        );
    }
}

ShowScores.propTypes = {
    changeScoreView: PropTypes.func.isRequired,
    showScores: PropTypes.bool.isRequired

};
const mapStateToProps = state => ({
    showScores: state.score.showScores,
});
export default connect(mapStateToProps, {changeScoreView})(ShowScores);
