import  moment from "moment-timezone";

const ConvertStartTime = (timestamp) => {
    return moment(timestamp*1000).calendar(null, {
        sameDay: 'HH:mm',
        nextDay: ' HH:mm',
        nextWeek: ' HH:mm',
        lastDay: ' HH:mm',
        lastWeek: 'HH:mm',
        sameElse: ' HH:mm'
    })
};
export  default ConvertStartTime;
