import React, {Component} from 'react';
import Info from "./Info";


class Event extends Component {
    getPreviewBtn() {
        if (process.env.REACT_APP_HIDE_LIVE_BTN==="true")
            return  ""
        if ((this.props.articleLink || this.props.hasStreams) && this.props.type !== 'finished')
            return (<a target="_blank" href={this.props.articleLink ? this.props.articleLink : `/title-event/${this.props.slug}/${this.props.id}?sport=${this.props.sport}` } className="preview-btn btn-sm btn btn-dark">Live</a>);
        if (this.props.type === 'finished' && this.props.hasHighlights)
            return (<a target="_blank" href={this.props.eventLink}
                       className="preview-btn btn btn-sm btn-dark">Highlights</a>);
        return "";
    }

    getLink = () => {
        if (process.env.REACT_APP_HIDE_LIVE_BTN==="true")
            return  "#";
        if (this.props.articleLink && this.props.type !== 'finished')
            return this.props.articleLink;


        return `/title-event/${this.props.slug}/${this.props.id}?sport=${this.props.sport}`;

    }

    render() {
        const link = this.getLink();
        return (

            <li style={{position:'relative',    borderBottom: '2px dashed #bdb7b7'}} className="title-event">
                <a target={link==="#" ? '_self' : '_blank'} href={link}>
                    <Info  {...this.props} statusDescription={this.props.statusDescription} type={this.props.type}
                          startTime={this.props.startTimestamp}/>
                    <span className="competition-cell-status-name" style={{
                        minHeight: '35px'
                    }}>{this.getPreviewBtn()}</span>
                </a>
            </li>

        );
    }

}





export default Event;
