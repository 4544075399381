import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvBox from "../Common/SvBox";
import Team from "./Team";
import Result from "./Result";
import {connect} from "react-redux";
import classnames from 'classnames';
import Tabs from "./Tabs";
import EventInfo from "./EventInfo";

class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goalIncidents: [],
            penaltyIncidents: [],
            sticky: false
        };
    }

    eventInfo() {
        return (<EventInfo venue={this.props.event.venue} startTimestamp={this.props.event.startTimestamp}/>);
    }

    render() {
        const props = this.props;
        const {homeTeam, awayTeam, homeScore, awayScore} = props.event;
        return (
            <div>
                <SvBox noRadius
                       titleLink={`/tournament/${props.event.tournament.slug}-live-stream/${props.event.tournament.id}?sport=${this.props.sport}`}
                       noMarginTop image={props.event.tournament.logo}
                       sub={this.eventInfo()}
                       title={props.event.tournament.name}>

                    <div className="match-view-headT">
                        <div className="match-view-head-side1">
                            <Team {...homeTeam}/>
                        </div>
                        <div className="match-view-headSS">
                            <Result type={props.event.status.type} startTimestamp={props.event.startTimestamp}
                                    homeScore={homeScore} awayScore={awayScore}
                                    statusDescription={props.event.statusDescription}/>
                        </div>
                        <div className="match-view-head-side2">
                            <Team {...awayTeam}/>
                        </div>
                    </div>

                    {/*<div className="text-center">*/}
                    {/*    <a*/}
                    {/*        target="_blank"*/}
                    {/*        style={{*/}
                    {/*            padding: '12px 70px'*/}
                    {/*        }} className="ad-button" href="https://pl.allsports4free.club/?st=soccerstreams.net"><i className="fa fa-play"/> Online Hd free</a>*/}
                    {/*</div>*/}
                </SvBox>
                <div className="clearfix"/>
                <div id="event-sticky-info">

                    <div className={classnames({
                        "hidden": !this.props.eventStickyInfo && this.state.sticky,
                    })}>
                        <Tabs sport={this.props.event.sport} type={this.props.event.status.type}/>
                    </div>
                </div>
            </div>
        );
    }
}

Info.propTypes = {
    event: PropTypes.object.isRequired,
    incidents: PropTypes.array.isRequired,
    showScores: PropTypes.bool.isRequired,
    search: PropTypes.bool.isRequired

};
const mapStateToProps = state => ({
    showScores: state.score.showScores,
    search: state.base.search,
    eventStickyInfo: state.base.eventStickyInfo,
    sport: state.sport.slug,
});
export default connect(mapStateToProps, {})(Info);
