import {CHANGE_SCORES_VIEW} from "../actions/types";

const initialState = {
    showScores: false
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_SCORES_VIEW:
            return {
                ...state,
                showScores: action.payload
            };
        default:
            return state;
    }
}