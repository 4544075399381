import React, {Component} from 'react';
import ConvertStartTime from "../../../utilites/convertStartTime";
import statusDescription from '../../../utilites/statusDescription';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class StatusDescription extends Component {

    render() {
        return (
            <span className={classnames('competition-cell-status', {
                "competition-cell-time": this.props.type === 'inprogress' || this.props.type === 'willcontinue'
            })}>
                {this.props.type === 'notstarted' ? (ConvertStartTime(this.props.startTime)) : ''}
                {this.props.type === 'inprogress' ||  this.props.type === 'willcontinue' ? (statusDescription(this.props.statusDescription)) : ''}
                {this.props.type === 'finished' ? ("Full time ") : ''}
                {this.props.type === 'canceled' ? (" canceled ") : ''}
            </span>
        );
    }
}

StatusDescription.propTypes = {
    type: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired
};
export default StatusDescription;
