import React, {Component} from 'react';
import SvBox from "../../Common/SvBox";
import {connect} from "react-redux";
import EventWays from "../EventWays";
import NordComponent from "../../Common/NordComponent";

class Streams extends Component {

    componentDidMount() {
        window.loadStreams(this.props.event.id,this.props.sport)
    }

    getTitle=()=>{
        if (this.props.sport==="title-event")
            return this.props.event.title + " Live Stream";
        const {homeTeam,awayTeam} = this.props.event;
        return homeTeam.name + " vs " + awayTeam.name + " Live Streams";
    }
    render() {


        return (
            <div>

                <SvBox preventUpperCaseTitle
                       title={this.getTitle()}>
                    {process.env.REACT_APP_PREMIUM_SERVICE_LINK ? (<NordComponent/>) : ''}
                    <div id="streams"></div>
                    <br/>
                    {!this.props.hideEventWays ? (
                    <EventWays/>
                    ) : ''}
                </SvBox>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    event: state.event.event,
    showStreams: state.event.showStreams,
});
export default connect(mapStateToProps, {})(Streams);
