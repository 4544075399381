import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {changeTheme, toggleLeftSidebar} from "../../actions/baseActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Nav, Navbar} from "react-bootstrap";
import axios from "../../modules/axios";

const ourSites = [
    {
        name: "NFL",
        icon: "icon-football-helmet",
        link: "https://official.nflstreams.to"
    },
    {
        name: "NBA",
        icon: "icon-basketball-ball",
        link: "https://nbastreams.to"
    },
    {
        name: "Soccer",
        icon: "icon-hockey-puck",
        link: "https://redi1.soccerstreams.net"
    },
    {
        name: "MLB",
        icon: "icon-baseball-ball",
        link: "https://mlbstreams.to"
    },
    {
        name: "MMA",
        icon: "icon-boxing-glove",
        link: "https://original.mmastreams.cc"
    },
    {
        name: "Boxing",
        icon: "icon-boxing-glove",
        link: "https://top.boxingstreams.cc"
    },
    {
        name: "Crack",
        icon: "icon-boxing-glove",
        link: "https://topp.crackstreams.gg"
    }
];

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenRightSideBar: false,
            ourSites: [],
        };
        this.toggleRightSideBar = this.toggleRightSideBar.bind(this);
    }

    componentWillMount() {
        axios()
            .get('/backlink/'+process.env.REACT_APP_SITE_UNIQUE_NAME+'/HEADER')
            .then(res=>{
                this.setState({
                    ourSites:res.data
                })
            })
    }

    toggleRightSideBar(toggle) {
        var width = document.body.offsetWidth;
        if (width >= 768 && toggle) {
            return;
        }
        this.setState({
            isOpenRightSideBar: toggle
        })

    }

    render() {

        const {sportData, styles} = this.props;
        return (
            <div>

                <Navbar
                    expand={"lg"}
                    style={{background: process.env.REACT_APP_BACKGROUND_CSS ? process.env.REACT_APP_BACKGROUND_CSS : styles.backgroundCSS, ...styles.cssStyles}}>
                    <div className="container">

                        <div style={{width: '100%'}}>
                            <Link className={"navbar-brand"} to="/">
                                {sportData.logoType === "image" ? (
                                    <img style={{height: sportData.logoHeight}} className="img-fluid"
                                         src={sportData.logo}
                                         alt=""/>
                                ) : (
                                    <h1 style={{color: process.env.REACT_APP_HEADER_AND_FOOTER_COLOR ? process.env.REACT_APP_HEADER_AND_FOOTER_COLOR : styles.headerAndFooterColor}}
                                        id="logo-type">{(process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : sportData.logo).toUpperCase()}</h1>
                                )}

                            </Link>
                            <div style={{width: '100%'}} className="d-flex align-items-center">

                                {sportData.founder ? (
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            color: process.env.REACT_APP_HEADER_AND_FOOTER_COLOR ? process.env.REACT_APP_HEADER_AND_FOOTER_COLOR : styles.headerAndFooterColor
                                        }}
                                        className="navbar-description">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by
                                        the
                                        founders of {sportData.founder}</span>
                                ) : ''}

                                <span className="ml-auto " style={{display: 'inline-block'}}>

                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ml-auto">

                                        {
                                            process.env.REACT_APP_HIDE_SITES_BAR != "true" ? (
                                                this.state.ourSites.map((item, key) => {
                                                    return <Nav.Link key={key} target={"_blank"}
                                                                     href={`https://${item.link}`}>{item.name}</Nav.Link>
                                                })
                                            ) : ''
                                        }

                                        <button style={{width: "40px", height: "40px"}}
                                                onClick={() => this.props.changeTheme(!this.props.darkTheme)}
                                                type="button" role="button"
                                                className={`btn btn-sm btn-${this.props.darkTheme ? 'dark' : 'light'}`}>
                                            <i className="icon-moon-regular"/>
                                        </button>
                                        {process.env.REACT_APP_DISCORD_LINK ? (
                                            <span>&nbsp; <a
                                                className={`btn btn-sm btn-${this.props.darkTheme ? 'dark' : 'light'}`}
                                                target="_blank"
                                                href={process.env.REACT_APP_DISCORD_LINK}> <img width={25}
                                                                                                className="img-fluid"
                                                                                                src="/images/discord-icon.png"
                                                                                                alt=""/></a></span>
                                        ) : ''}
                                    </Nav>
                                </Navbar.Collapse>
                                </span>

                            </div>


                        </div>


                        {/*<div className="ml-auto" style={{position: 'relative'}}>*/}
                        {/*    <Navbar.Toggle/>*/}
                        {/*    <Navbar.Collapse className="justify-content-end">*/}
                        {/*        <Navbar.Text>*/}
                        {/*            Signed in as: <a href="#login">Mark Otto</a>*/}
                        {/*        </Navbar.Text>*/}
                        {/*    </Navbar.Collapse>*/}
                        {/*    <ThemeSwitcher/>*/}
                        {/*</div>*/}
                    </div>
                </Navbar>
            </div>
        );
    }

}

Header.propTypes = {
    toggleLeftSidebar: PropTypes.func.isRequired,
    leftSideBar: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
    leftSideBar: state.base.leftSideBar,
    darkTheme: state.base.darkTheme,
    sportData: state.sport.sportData,
    styles: state.base.styles
});

export default connect(mapStateToProps, {toggleLeftSidebar, changeTheme})(Header);

