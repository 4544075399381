import React,{Component} from 'react'
class EventInfo extends Component{
    date(unix_timestamp){
        var a = new Date(unix_timestamp * 1000);
        var year = a.getFullYear();
        var month = a.getMonth() + 1;
        var date = a.getDate();
        var time = year + '-' + month + '-' + date ;
        return time;
    }
    render() {
        return (
            <small style={{color:"#777777"}}>
                <span><i style={{verticalAlign:"middle"}} className="fa fa-calendar"/> {this.date(this.props.startTimestamp)} </span>&nbsp;
                {this.props.venue ? (<span><img style={{width:"18px"}} src="/images/stadium.png" alt=""/> {this.props.venue.stadium.name}</span>) : ''}

            </small>
        );
    }
}
export default EventInfo;