import React, {Component} from 'react';
import axios from '../../modules/axios'
import SvBox from "../Common/SvBox";
import Info from "./Info";
import TopTournament from "../Index/Matches/TopTournament";
import Layout from "../Layout";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import StandingTable from "../Standings/StandingTable";

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            tournaments: [],
            team: {},
            matches: []
        };
        this.fetchData = this.fetchData.bind(this);
    }

    componentWillMount() {
        const props = this.props;
        this.setState({
            id: props.match.params.id,
        }, () => {
            this.fetchData();

        });
    }

    componentDidMount() {
        this.scrollToTop();
    }

    fetchData() {
        axios().get('/team/' + this.state.id + "?type=" + this.props.sportSlug)
            .then(res => {
                this.setState({
                    team: res.data.team,
                    tournaments: res.data.tournaments,
                    matches: res.data.matches,
                })
            })
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    componentWillReceiveProps() {
        this.scrollToTop();
    }

    render() {
        return (<Layout>
            {Object.keys(this.state.team).length ? (
                <div>
                    <Helmet>
                        <title>{this.state.team.name + " live streams"}</title>
                        <meta property="og:title"
                              content={this.state.team.name + " live streams"}/>
                        <meta property="og:description"
                              content={this.state.team.name + " fixtures,streams"}/>
                        <meta property="og:image" content={this.state.team.logo}/>
                    </Helmet>
                    < SvBox noMarginTop>
                        < Info team={this.state.team}/>
                    </SvBox>
                    <br/>
                    <SvBox name="" title="Matches">
                        {this.state.matches.map((tournament, i) => {
                            return (<TopTournament key={i} tournament={tournament}/>);
                        })}
                    </SvBox>
                    {process.env.REACT_APP_TOURNAMENT_ID && process.env.REACT_APP_SEASON_ID ? (
                        <StandingTable
                            homeTeamId={this.state.team.id}
                            tournamentId={process.env.REACT_APP_TOURNAMENT_ID}
                            seasonId={process.env.REACT_APP_SEASON_ID}

                        />
                    ) : ''}
                </div>
            ) : ''}
        </Layout>);
    }

}

const mapStateToProps = state => ({
    sportSlug: state.sport.name,
});
export default connect(mapStateToProps, {})(Team);
