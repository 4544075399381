import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

function Team(props) {
        return (
            <div className="event-team">
                <Link to={"/team/" + props.slug + "-live-stream/" + props.id}>
                    <img src={props.logo} alt={props.name}/>
                    <h5>{props.name}</h5>
                </Link>
            </div>
        );
}

Team.propTypes = {
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};
export default React.memo(Team);
