import {
    AGREE_COOKIES,
    CHANGE_THEME, ENABLE_EMBED,
    FETCH_COUNTRIES, FETCH_POPULAR_COUNTRIES,
    FETCH_TOP_TEAMS,
    HIDDEN_SEARCH, SET_STYLES,
    SHOW_SEARCH, TOGGLE_LEFT_SIDEBAR, TOGGLE_STICKY_INFO
} from "../actions/types";

const initialState = {
    darkTheme: true,
    eventStickyInfo: true,
    cookie: false,
    search: false,
    leftSideBar: false,
    topTeams: [],
    countries: [],
    popularCountries: [],
    embed:false,
    styles:{},

};
export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_THEME:
            return {
                ...state,
                darkTheme: action.payload
            };
        case AGREE_COOKIES:
            return {
                ...state,
                cookie: action.payload
            };
        case SHOW_SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case HIDDEN_SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case FETCH_TOP_TEAMS:
            return {
                ...state,
                topTeams: action.payload
            };
        case FETCH_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        case FETCH_POPULAR_COUNTRIES:
            return {
                ...state,
                popularCountries: action.payload
            };
        case TOGGLE_LEFT_SIDEBAR:
            return {
                ...state,
                leftSideBar: action.payload
            };
        case TOGGLE_STICKY_INFO:
            return {
                ...state,
                eventStickyInfo: action.payload
            };
        case ENABLE_EMBED:
            return {
                ...state,
                embed: action.payload
            };
        case SET_STYLES:
            return {
                ...state,
                styles: action.payload
            };
        default:
            return state;
    }
}
