export const CHANGE_SCORES_VIEW = 'CHANGE_SCORES_VIEW';
export const SHOW_HIGHLIGHT = 'SHOW_HIGHLIGHT';
export const STORE_HIGHLIGHTS  = 'STORE_HIGHLIGHTS';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_EVENT_TAB_DATA = 'CHANGE_EVENT_TAB_DATA';
export const AGREE_COOKIES = 'AGREE_COOKIES';
export const SHOW_SEARCH = 'SHOW_SEARCH';
export const HIDDEN_SEARCH = 'HIDDEN_SEARCH';
export const FETCH_TOP_TEAMS ='FETCH_TOP_TEAMS';
export const FETCH_POPULAR_COUNTRIES ='FETCH_POPULAR_COUNTRIES';
export const FETCH_COUNTRIES ='FETCH_COUNTRIES';
export const TOGGLE_LEFT_SIDEBAR ='TOGGLE_LEFT_SIDEBAR';
export const SET_EVENT_EMBED ='SET_EVENT_EMBED';
export const STORE_OTHER_NATCHES ='STORE_OTHER_NATCHES';
export const STORE_EVENT_EMBED ='STORE_EVENT_EMBED';
export const SET_VIDEO_TYPE ='SET_VIDEO_TYPE';
export const SET_EMBED_LOADING ='SET_EMBED_LOADING';
export const TOGGLE_STICKY_INFO ='TOGGLE_STICKY_INFO';
export const FETCH_EVENT_DATA ='FETCH_EVENT_DATA';
export const TOGGLE_EVENT_LOADING ='TOGGLE_EVENT_LOADING';
export const ENABLE_EMBED ='ENABLE_EMBED';
export const SET_SPORT_DATA ='SET_SPORT_DATA';
export const SET_MAIN_SEASON ='SET_MAIN_SEASON';
export const SET_TEAMS ='SET_TEAMS';
export const SET_SELECTED_TEAMS ='SET_SELECTED_TEAMS';
export const SET_RANDOM ='SET_RANDOM';
export const SET_LEAGUES ='SET_LEAGUES';
export const SET_STYLES ='SET_STYLES';




