import React, {Component} from 'react';
import SvBox from "../Common/SvBox";
import Layout from "../Layout";

class PrivacyPolicy extends Component {
    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    componentDidMount() {
        this.scrollToTop();
    }

    componentWillReceiveProps() {
        this.scrollToTop();

    }

    render() {
        return (
            <Layout>

                <SvBox largeTitle name="Privay Policy">
                    <p>
                        This Privacy Policy governs the way in which SportsVideo collects and uses information from Our
                        users (each, a "User") of the SoccerStreams site ("Site").
                        <br/>
                        <h5>
                            PERSONAL IDENTIFICATION INFORMATION
                        </h5>
                        We may collect personal identification information from Users in a variety of ways in connection
                        with activities, services, features or resources we make available on our Site. We will collect
                        personal identification information from Users only if they voluntarily submit such information
                        to
                        us. Users can always refuse to supply personally identification information, except that it may
                        prevent them from engaging in certain Site related activities.
                        <br/>
                        <h5>
                            NON-PERSONAL IDENTIFICATION INFORMATION
                        </h5>
                        We may collect non-personal identification information about Users whenever they interact with
                        our
                        Site. Non-personal identification information may include the browser name, the type of computer
                        and
                        technical information about Users means of connection to our Site, such as the operating system
                        and
                        the Internet service providers utilized and other similar information.

                        <br/>
                        <h5>
                            ADVERTISING
                        </h5>
                        Ads appearing on our site may be delivered to Users by advertising partners, who may set
                        cookies.
                        These cookies allow the ad server to recognize your computer each time they send you an online
                        advertisement to compile non personal identification information about you or others who use
                        your
                        computer. This information allows ad networks to, among other things, deliver targeted
                        advertisements that they believe will be of most interest to you. This privacy policy does not
                        cover
                        the use of cookies by any advertisers.
                        <br/>
                        <h5> GOOGLE ADSENSE</h5>
                        Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads
                        to
                        Users based on their visit to our Site and other sites on the Internet. DART uses "non
                        personally
                        identifiable information" and does NOT track personal information about you, such as your name,
                        email address, physical address, etc. You may opt out of the use of the DART cookie by visiting
                        the
                        Google ad and content network privacy policy at http://www.google.com/privacy_ads.htm
                        <br/>
                        <h5> ACCEPTANCE OF THESE TERMS
                        </h5>
                        By using this Site, you signify your acceptance of this policy. If you do not agree to this
                        policy,
                        please do not use our Site. Your continued use of the Site following the posting of changes to
                        this
                        policy will be deemed your acceptance of those changes.
                    </p>
                </SvBox>
            </Layout>

        );
    }

}

export default PrivacyPolicy;