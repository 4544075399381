import {
    SET_MAIN_SEASON,
    SET_SELECTED_TEAMS,
    SET_SPORT_DATA,
    SET_TEAMS,
    SET_RANDOM,
    SET_LEAGUES
} from "../actions/types";

const initialState = {
    slug: "",
    endpoint: "/top-matches/football/",
    color: "",
    sportData: {},
    mainSeason: {},
    teams: [],
    random: Math.random(),
    selectedTeams: [],
    leagues:[],
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SPORT_DATA: {
            return {
                ...state,
                slug: action.payload.slug,
                endpoint: action.payload.endpoint,
                color: action.payload.color,
                sportData: action.payload
            }
        }
        case SET_MAIN_SEASON:
            return {
                ...state,
                mainSeason: action.payload
            }
        case SET_TEAMS:
            return {
                ...state,
                teams: action.payload,
                random: Math.random()
            };
        case SET_SELECTED_TEAMS:
            return {
                ...state,
                selectedTeams: action.payload,
                random: Math.random()
            };
        case SET_RANDOM:
            return {
                ...state,
                random: Math.random()
            };
        case SET_LEAGUES:
            return {
                ...state,
                leagues:action.payload
            };
        default:
            return state;
    }
}
