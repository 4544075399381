import React,{Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class EventWays extends Component{
    getHomeTeam() {
        const team = this.props.event.homeTeam;
        const HomeTeamLink = (<Link to={"/team/" + team.slug + "-live-stream/" + team.id}>{team.name}</Link>);
        return HomeTeamLink;
    }

    getAwayTeam() {
        const team = this.props.event.awayTeam;
        const AwayTeamLink = (<Link to={"/team/" + team.slug + "-live-stream/" + team.id}>{team.name}</Link>);
        return AwayTeamLink;
    }
    getYearFromTimestamp() {
        var d = new Date(this.props.event.startTimestamp * 1000);
        return d.getFullYear();

    }
    render() {
        return (
            <div>
                <p id="event-tags">
                    {this.getHomeTeam()} vs <a href="#">{this.props.event.awayTeam.name}</a> live stream,&nbsp;
                    {this.getAwayTeam()} vs {this.getHomeTeam()} live stream,&nbsp;
                    {this.getHomeTeam()} vs {this.getAwayTeam()} online free,&nbsp;
                    {this.getHomeTeam()} vs {this.getAwayTeam()} free stream,&nbsp;
                    {this.getHomeTeam()} vs {this.getAwayTeam()} online,&nbsp;
                    {this.getHomeTeam()} vs {this.getAwayTeam()} <Link to={"/tournament/" + this.props.event.tournament.slug+"-live-stream/" + this.props.event.tournament.id}>{this.props.event.tournament.uniqueName}</Link> live {this.getYearFromTimestamp()}
                </p>
            </div>
        );
    }

}
const mapStateToProps = state => ({
    event: state.event.event,
});
export default connect(mapStateToProps, {})(EventWays);