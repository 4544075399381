import React from 'react'
import PropTypes from 'prop-types'

class Header extends React.PureComponent {
    getTitle(){
        if (!this.props.title )
            return '';
        if (this.props.preventUpperCaseTitle)
            return  this.props.title;
        return  this.props.title.toUpperCase();
    }
    render() {
        return (
            <div className="header">
                <div className="logo">
                    {this.props.img ? (<img src={this.props.img} alt={this.props.title}/>) : ''}
                </div>
                <div className="title"  style={{fontSize:this.props.largeTitle ? '30px' : ''}} >
                    <span style={{fontSize:this.props.largeTitle ? '30px' : ''}} className="name">{this.props.name}</span>
                    {this.getTitle()}
                {this.props.sub ? (<div>{this.props.sub}</div>) : ''}
                </div>

            </div>
        );
    }
}

Header.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string.isRequired
};
export default Header;
