import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Info from "./Info";
import {connect} from "react-redux";

class Competition extends Component {
    getSportQueryString = () => {
        if (this.props.embed)
            return "?sport=" + this.props.sportSlug;
        return "";
    };

    getPreviewBtn() {


        if (this.props.redditEventLink && this.props.type !== 'finished' && this.props.type !== 'canceled')
            return (
                <a href={"/" + process.env.REACT_APP_EVENT_PREFIX + "/" + this.props.slug + "-live-stream/" + this.props.id}
                   className="btn btn-sm btn-ss">Live
                    Streams</a>);
        if (this.props.type === 'finished' && this.props.hasHighlights)
            return (<a target="_blank" href={this.props.eventLink} className="btn btn-sm btn-ss">Show Highlights</a>);
        return "";
    }

    getLink = () => {
        return `/${process.env.REACT_APP_EVENT_PREFIX}/${this.props.slug}-live-stream/${this.props.id}${this.getSportQueryString()}`
    }

    render() {
        return (
            <li>
                <div className="competition">
                    <a href={this.getLink()}>
                        <Info
                            statusDescription={this.props.statusDescription}
                            startTimestamp={this.props.startTimestamp}
                            sport={this.props.sport} type={this.props.type} homeTeam={this.props.homeTeam}
                            awayTeam={this.props.awayTeam}
                            homeScore={this.props.homeScore} awayScore={this.props.awayScore}/>
                    </a>
                    <span className="competition-cell-status-name" style={{minHeight: "35px"}}>
                        {this.getPreviewBtn()}
                    </span>

                </div>
            </li>
        );
    }

}

Competition.propTypes = {
    type: PropTypes.string.isRequired,
    homeScore: PropTypes.object.isRequired,
    awayScore: PropTypes.object.isRequired,
    homeTeam: PropTypes.object.isRequired,
    awayTeam: PropTypes.object.isRequired,
    startTime: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,

};

const mapStateToProps = state => ({
    sportSlug: state.sport.slug,
    embed: state.base.embed
});
export default connect(mapStateToProps, {})(Competition);
