import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import DayInfo from "./DayInfo";

class Day extends Component {
    render() {
        const currentDate = this.props.currentDate.format('YYYY-MM-DD');
        const date = this.props.date.format('YYYY-MM-DD');
        const today = this.props.today.format('YYYY-MM-DD');
        const  name = this.props.name;
        return (
            <li onClick={() => date !== currentDate ? this.props.onChange(this.props.date) : ''} className={classnames({
                "selected": date === currentDate,
                "today": date === today,
            })}>
                <DayInfo name={name} day={this.props.date}/>
            </li>
        );
    }
}

Day.propTypes = {
    currentDate: PropTypes.any.isRequired,
    date: PropTypes.any.isRequired,
    today: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    showMonth :  PropTypes.bool.isRequired
};
export default Day;