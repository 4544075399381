import React, {Component} from 'react';
import SvBox from "../Common/SvBox";
import ReCAPTCHA from "react-google-recaptcha";
import axios from '../../modules/axios'
import Layout from "../Layout";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            status: true,
            message: '',

        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();
        const {captcha, email} = this.state;
        if (captcha === '' || email === '') {
            return;
        }
        var bodyFormData = new FormData();
        bodyFormData.set('captcha', captcha);
        bodyFormData.set('email', email);

        axios()
            .post(`/soccerstreams-signup?email=${email}&captcha=${captcha}`)
            .then(res => {
                this.setState({
                    email: '',
                    captcha: '',
                });
                this.refs.recaptcha.reset();

                return this.setState({
                    message: res.data.message,
                    status: true,
                });
            }).catch(res => {
            if (res.hasOwnProperty('response') && res.response) {
                if (res.response.hasOwnProperty('data')) {
                    return this.setState({
                        message: res.response.data.message,
                        status: false,
                    })
                }

            }

        })


    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeRecaptcha(value) {
        this.setState({
            captcha: value
        });
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    componentDidMount() {
        this.scrollToTop();
    }

    componentWillReceiveProps() {
        this.scrollToTop();

    }

    render() {
        return (
            <Layout>
                <SvBox>
                    <h2 className="text-center">How To Stay In Touch With Us</h2>
                    <br/>
                    <p>
                        Discord has been taking up a lot of our time, hindering our ability to focus on what we do best:
                        moderate links.
                        <br/>
                        Due to this fact, we have decided to move off Discord and keep in touch with you guys via email.
                        <br/>
                        <br/>
                        Here are a few reasons for why:
                        <br/>
                        <br/>
                        <ul>
                            <li>It's a much simpler process for us.</li>
                            <br/>
                            <li>We don't have to live in fear of Discord taking our server down like Reddit did (they both
                                use the same lawyers).</li>
                            <br/>
                            <li>It is much safer for us, as there are no liabilities involved in consentual emails.</li>
                            <br/>
                            <li>We no longer have to disturb you with pings.</li>
                            <br/>
                            <li>You will have the ability to unsubscribe from our emails us at any time.</li>
                        </ul>

                    </p>
                    <br/>
                    <div className="row justify-content-center">

                        <div className="col-md-6">
                            <div style={{display: this.state.showMessage ? 'block' : 'none'}}
                                 className="alert alert-success">
                                <p>Your feedback has been saved successfully.Thank you</p>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input onChange={this.onChange} value={this.state.email} required type="email"
                                           name="email" className="form-control"/>
                                    {this.state.message ? (
                                        <div style={{display: 'block'}}
                                             className={(this.state.status ? 'valid' : 'invalid') + '-feedback'}>
                                            {this.state.message}
                                        </div>
                                    ) : ''}
                                </div>

                                <div className="text-center">

                                    <ReCAPTCHA ref="recaptcha" onExpired={() => this.setState({
                                        captcha: ''
                                    })}
                                               sitekey="6Lf2OIIUAAAAAGQRVR-fwPmchGg120QA2b1-Nwtb"
                                               onChange={this.onChangeRecaptcha}
                                    />
                                    <br/>
                                    <button className="btn btn-success">Submit</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </SvBox>
            </Layout>
        );
    }

}

export default SignUp;