import React from 'react'
import PropTypes from 'prop-types'

class Body extends React.PureComponent {

    render() {
        return (
            <div className="body">
                {this.props.body}
            </div>
        );
    }

}

Body.propTypes = {
    body: PropTypes.any.isRequired
};
export default Body;
