import React, {Component} from 'react';
import Day from "./Calendar/Day";
import classnames from 'classnames';
import moment  from 'moment-timezone'
class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCalendar: false,
            subDays: 0,
            addDays: 0,
            dates: []
        };
        this.toggleShowCalendar = this.toggleShowCalendar.bind(this);
    }

    addDay(date, days) {
        var result = moment().add(days, 'days');
        return result;
    }

    subDay(date, days) {
        var result = moment().subtract(days, 'days');
        return result;
    }

    getDates() {
        const date =  moment();
        let dates = [];
        dates.push(this.subDay(date, 7))
        dates.push(date);
        dates.push(this.addDay(date, 7))
        return dates;
    }



    toggleShowCalendar() {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }

    componentDidMount() {
        this.setState({
            dates: this.getDates()
        })
    }

    render() {
        const currentDate = this.props.date;
        const today = moment();
        const props = this.props;

        return (
            <div>

                <ul id="mini-calendar" className={classnames("calendar-matches", {
                    "hide": this.state.showCalendar
                })}>
                    {this.state.dates.length ? (
                        <div>
                            <Day showMonth={false} onChange={props.onChange} currentDate={currentDate}
                                 date={this.state.dates[0]}
                                 name={"Last Week"}
                                 today={today}/>
                            <Day showMonth={false} onChange={props.onChange} currentDate={currentDate}
                                 date={this.state.dates[1]}
                                 name={"This Week"}
                                 today={today}/>
                            <Day showMonth={false} onChange={props.onChange} currentDate={currentDate}
                                 date={this.state.dates[2]}
                                 name={"Next Week"}
                                 today={today}/>
                        </div>
                    ) : ''}

                </ul>


            </div>
        );
    }
}

Calendar.propTypes = {};
export default Calendar;
