import React from 'react';
import PropTypes from 'prop-types'
import Header from './SvBox/Header'
import Body from "./SvBox/Body";
import {Link} from 'react-router-dom'
import classnames from 'classnames';
function SvBox(props) {
    return (
        <div  style={{marginTop:props.noMarginTop ? '0px' :'15px'}} className={classnames("sv-box "+props.className,{
            "no-radius":props.noRadius
        })}>
            {props.right ? (<div className="right-side" >{props.right}</div>) : ''}
            {props.name ||props.title  ?
                props.titleLink ? (
                    <Link  to={props.titleLink}> <Header preventUpperCaseTitle={props.preventUpperCaseTitle} name={props.name} sub={props.sub} title={props.title} img={props.image}/> </Link>

                ) : (  <Header preventUpperCaseTitle={props.preventUpperCaseTitle} largeTitle={props.largeTitle} sub={props.sub} name={props.name} title={props.title} img={props.image}/>)
                : ''}
            <div className="clearfix"/>
            <Body body={props.children}/>
        </div>
    );
}

SvBox.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    children: PropTypes.any.isRequired,
    titleLink:PropTypes.string


};
export default React.memo(SvBox);
